
export default {
  name: "DarkMode",
  data() {
    return {
      isDarkMode: false,
    };
  },
  watch: {
    isDarkMode(newValue) {
      this.changeTheme(newValue);
    },
  },
  mounted() {
    this.setInitialTheme();
  },
  methods: {
    setInitialTheme() {
      const savedTheme = localStorage.getItem("theme") || "light";
      this.isDarkMode = savedTheme === "dark";
      this.applyTheme(this.isDarkMode);
    },
    changeTheme(isDarkMode) {
      const theme = isDarkMode ? "dark" : "light";
      this.applyTheme(isDarkMode);
      localStorage.setItem("theme", theme);
    },
    applyTheme(isDarkMode) {
      if (isDarkMode) {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
    },
  },
};
