
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";
export default {
  name: "Nav",
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      show: "",
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(["divisions", "districts"]),
  },
  watch: {
    $route() {
      this.show = "";
      this.showMenu = false;
    },
  },
};
