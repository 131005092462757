
import { initLottie } from "@/utils";

export default {
  name: "NotFound",
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
